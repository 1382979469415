<template>

<app-content :loading="is.loading && !is.initialised" class="browse">

	<app-content-filters>

		<app-content-filters-search v-model="filter.search" />
		<app-content-filters-select v-model="filter.sort" prefix="Sort by" text="Sort by" tooltip="Change sorting" :options="$util.options(filterSort)" />
		<app-content-filters-button v-on:click="onButtonClick" text="More filters" tooltip="Expand for many more filters" />

		<app-panel v-if="expanded" v-on:close="onButtonClick">

			<app-panel-title text="Game" />

			<app-panel-content>

				<app-input-slider type="range" label="Recommended count" :min="1" :max="10" maxText="Any" v-model="filter.game.count.recommended" />
				<app-input-slider type="range" label="Best count" :min="1" :max="10" maxText="Any" v-model="filter.game.count.best" />
				<app-input-slider label="Weight" type="range" :min="0" :max="5" :step="0.1" :pip="0.5" v-model="filter.game.weight" />
				<app-input-slider label="Published year" type="range" :min="1972" :max="2022" :step="1" :pip="10" v-model="filter.game.year" />
				<app-input-slider type="range" label="Language independence" :inverse="true" :min="1" :max="5" :texts="$constants.schedule.languageSlider" v-model="filter.game.language" />

			</app-panel-content>

			<app-panel-title text="BGG Ratings" />

			<app-panel-content>

				<app-input-slider type="range" label="Average rating" :min="0" :max="10" v-model="filter.rating.community" />
				<app-input-slider type="range" label="Rank" :min="0" :max="1000" :step="25" :pip="100" maxText="Any" v-model="filter.rating.rank" />

			</app-panel-content>

			<app-panel-title text="Other filters" />

			<app-panel-content>

				<app-input-suggest :stacked="true" v-model="filter.other.designers" api="bgg/designer" placeholder="Search designers" />
				<app-input-suggest :stacked="true" v-model="filter.other.publishers" api="bgg/publisher" placeholder="Search publishers" />
				<app-input-suggest :stacked="true" v-model="filter.other.categories" api="bgg/category" placeholder="Search categories" />
				<app-input-suggest :stacked="true" v-model="filter.other.mechanics" api="bgg/mechanic" placeholder="Search mechanics" />
				<app-input-suggest :stacked="true" v-model="filter.other.families" api="bgg/family" placeholder="Search families" />
			
			</app-panel-content>

		</app-panel>

	</app-content-filters>

	<app-content-body :no-padding="true" :loading="is.loading && is.initialised && !this.scrolling" :is-empty="!collection.length" placeholder="Sorry, no games found." v-on:scrolled="onScrollLoad">

		<app-table>

			<app-table-head :layout="layout" :is-fixed="true">

				<app-table-column text="Locate" align="center" />
				<app-table-column text="Game" />
				<app-table-column />
				<app-table-column icon="users" align="center" />
				<app-table-column icon="clock" align="center" />
				<app-table-column icon="weight" align="center" />
				<app-table-column text="BGG" align="center" />

			</app-table-head>

			<app-table-body :fixed-header="true">

				<app-table-row v-tooltip="'Click to view BGG page'" v-on:click="onGameClick(item.bgg_game.id)" :danger="item.status === $constants.games.status.checkedout" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-shelf :value="item.shelf" :status="item.status" />
					<app-table-cell-image :image="item.bgg_game.image"  />
					<app-table-cell-text :text="item.name" :subtext="item.version" />
					<app-table-cell-text :text="countRange(item.bgg_game.count)" align="center" />
					<app-table-cell-text :text="durationRange(item.bgg_game.duration)" align="center" />
					<app-table-cell-text v-if="item.bgg_game.weight" :text="roundBand(item.bgg_game.weight)" :subtext="roundWeight(item.bgg_game.weight)" align="center" />
					<app-table-cell-text v-if="!item.bgg_game.weight" text="-" align="center" />
					<app-table-cell-rating :value="item.bgg_game.rating.value" :count="item.bgg_game.rating.count" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" :is-scroller="true" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			scrolling: true,
			expanded: false,
			filterTemplate: 'library',
			filter: {
				sort: 'name',
				layout: 'list'
			},
			filterSort: {
				name: 'Name',
				shelf: 'Shelf',
				added: 'Most recent'
			},
			filterLayout: {
				list: 'List',
				card: 'Cards'
			},
			endpoint: 'games',
			live: 'games',
			layout: '48px 48px auto 80px 80px 80px 48px'
		}

	},

	computed: {

		tabs: function() {

			return [
				{ name: 'Convention.Library.Browse', text: 'Games' },
				{ name: 'Convention.Library.Categories', text: 'Categories' },
				{ name: 'Convention.Library.Mechanics', text: 'Mechanics' }
			]

		}

	},

	methods: {

		onButtonClick: function() {

			this.expanded = !this.expanded

			this.$pubsub.$emit('offset', this.expanded)

		},

		onGameClick: function(id) {

			window.open('https://boardgamegeek.com/boardgame/' + id.toString(), '_blank').focus();

		},

		roundWeight: function(value) {

			return value.toFixed(2)

		},

		roundBand: function(value) {

			if (value <= 2) {

				return 'Light'

			} else if (value <= 3) {

				return 'Light-Medium'

			} else if (value <= 4) {

				return 'Medium'

			} else if (value <= 5) {

				return 'Heavy'

			}

		},

		countRange: function(value) {

			if (value.min || value.max) {

				if (value.min !== value.max && value.max > value.min) {

					return value.min.toString() + ' - ' + value.max.toString()

				} else if (value.min) {

					return value.min

				} else {

					return value.max

				}

			} else {

				return '-'

			}

		},

		durationRange: function(value) {

			if (value.min > 14 || value.max > 14) {

				if (value.min !== value.max && value.max > value.min) {

					return value.min.toString() + ' - ' + value.max.toString()

				} else if (value.min) {

					return value.min

				} else {

					return value.max

				}

			} else {

				return '-'

			}

		}

	}

}

</script>

<style scoped>

.browse {
	padding-top: var(--head-height);
}

.browse >>> .head,
.browse >>> .row {
	padding: 0px 15px;
}

</style>